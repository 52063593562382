import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Index'),
        },
        {
          name: 'FNTP',
          path: '',
          component: () => import('@/views/FNTP'),
        },
        {
          name: 'Reduction',
          path: '/reduction',
          component: () => import('@/views/Reduction'),
        },
        // Tables
        {
          name: 'Contact',
          path: 'contact',
          component: () => import('@/views/Contact'),
        },
      ],
    },
  ],
})
